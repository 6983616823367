<template>
    <div class="home">
        <swiper :options="swiperOption" class="swiperBlock flexItem110">
            <swiper-item class="flexColStartRowCenter" v-for="(item,index) in banner" :key="index">
                <img :src="item.img" @click="bannerClick(item,index)">
            </swiper-item>
        </swiper>
        <image-box :src="require('../../assets/image/home/home_1.jpg')" :height="735">
            <a class="home-1-button" href="https://v.qq.com/x/page/w3364gkj6qp.html" target="_blank"></a>
        </image-box>
        <image-box :src="require('../../assets/image/home/home_2.jpg')" :height="545">
            <a class="home-2-button" href="https://v.qq.com/x/page/w3518lsozrd.html" target="_blank"></a>
        </image-box>
        <image-box :src="require('../../assets/image/home/home_3.jpg')" :height="643" clickable @click="jumpPage('nutrition')">
        </image-box>
        <image-box :src="require('../../assets/image/home/home_4.jpg')" :height="610" clickable @click="jumpPage('brainfit')">
        </image-box>
        <image-box :src="require('../../assets/image/home/home_5.jpg')" :height="1083"></image-box>
        <image-box :src="require('../../assets/image/home/home_6.jpg')" :height="890">
            <div class="home-6-button" @click="jumpPage('academic')"></div>
        </image-box>
        <image-box :src="require('../../assets/image/home/home_7.jpg')" :height="655">
            <div class="home-7-button" @click="jumpPage('articleList')"></div>
        </image-box>
        <image-box :src="require('../../assets/image/home/home_8.jpg')" :height="633"></image-box>
    </div>
</template>

<script>
import {swiper, swiperItem} from '../../components/swiper/index'
import ImageBox from "../../components/ImageBox/ImageBox";

export default {
    name: "home",
    components: {
        "swiper": swiper,
        "swiper-item": swiperItem,
        "image-box": ImageBox
    },
    data() {
        return {
            swiperOption: {
                pageBtn: true,
                height: "auto",
                width: "100%",
                callback: () => {
                },
                pagination: {
                    show: true,
                    canClick: true
                },
                current: 0,
                itemsNum: 7,
                loop: true,
                loopTime: 5000,
                auto: true
            },
            banner: [
                {
                    img: require("../../assets/image/home/banner0.jpg?v=1"),
                  url: "/investment",
                  type: "route"
                },
                {
                    img: require("../../assets/image/home/banner1.jpg?v=1"),
                    url: "https://v.qq.com/x/page/w3364gkj6qp.html",
                    type: "blank"
                },
                {
                    img: require("../../assets/image/home/banner2.jpg?v=1"),
                    url: "/nutrition",
                    type: "route"
                },
                {
                    img: require("../../assets/image/home/banner3.jpg?v=1"),
                    url: "/brainfit",
                    type: "route"
                },
                {
                    img: require("../../assets/image/home/banner4.jpg?v=1"),
                    type: "none"
                },
                {
                    img: require("../../assets/image/home/banner5.jpg?v=1"),
                    url:"#/article?id=62e3c4b0e4b0c9cb06b3a839",
                    type: "blank"
                },
                {
                    img: require("../../assets/image/home/banner6.jpg?v=1"),
                    url: "/cooperation",
                    type: "route"
                }
            ]
        }
    },
    methods: {
        jumpPage(name) {
            this.$router.push({path: "/" + name})
        },
        reject() {
            alert("报名已结束")
        },
        bannerClick(item, i) {
            if (item.type === "route") {
                this.$router.push({path: item.url})
            } else if (item.type === "blank") {
                window.open(item.url, "_blank")
            } else {

            }
        }
    }
}

</script>

<style scoped lang="scss">
@media screen and (min-width: $minDeviceWidth_pc) {
    @import "home_pc";
}

@media screen and (max-width: $minDeviceWidth_pc) {
    @import "home_mobile";
}
</style>
